import { Store, AnyAction } from "redux";
import { Dispatch } from "react";

const logger = (store : Store) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    console.group(action.type);
    console.log(action.data);
    const returnValue = next(action);
    console.log(store.getState());
    console.groupEnd();
    return returnValue
};

export default logger;
