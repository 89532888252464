import { Provider } from 'react-redux';
import React, { FC } from 'react';
import { routerConfiguration } from '@nimles/react-web-components-redux';
import { configuration } from '@nimles/rest-api';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { initStore } from './redux/init';
import { SiteMetadata } from './types';
import { constants } from './utils';

configuration.apiRoot = 'https://quotes.azurewebsites.net/api/nimles/';
configuration.identityRoot = 'https://quotes.azurewebsites.net/api/identity/';

export const Initializer: FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          name
          tenantId
          clientId
        }
      }
    }
  `);

  const siteMetadata: SiteMetadata = data.site.siteMetadata;

  constants.tenantId = siteMetadata.tenantId;

  const store = initStore({
    tenantId: siteMetadata.tenantId,
    clientId: siteMetadata.clientId,
    localStorageName: siteMetadata.name + 'redux',
  });

  routerConfiguration.linkComponent = Link;

  return <Provider store={store}>{children}</Provider>;
};
